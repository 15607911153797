$mainComponent: 'LeaderBox';
$component: 'Newsletter';

##{$mainComponent}--newsletter{
    margin-top: 24px;
    padding: 0px 24px;
    .#{$mainComponent}--Information{
        .isDesktop & {
            display: flex;
        }
        .isMobile & {
            margin-bottom: 32px;
        }
        flex-direction: row;
        .#{$component}--Left{
            width: 50%;
            .isMobile & {
                width: 100%;
                float: left;
            }
            .isDesktop &.isContactForm{
                width: 25%;
            }
            padding-right: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h1{
                font-size: 32px;
                line-height:32px;
                padding-bottom: 22px;
                .isMobile &{
                    margin-top: 24px;
                }
                small{
                    font-size:12px;
                    color: #333;
                }
                i{
                    float: left;
                    margin-top: 10px;
                    margin-right: 18px;
                }
            }
            button{
                margin-top: 24px;
            }
        }
        .#{$component}--Right{
            width: 50%;
            .isMobile & {
                width: 100%;
                float: left;
                margin-top: 24px;
            }
            .isDesktop &.isContactForm{
                width: 75%;
                .isDesktop & {
                    padding-left: 5%;
                }
            }
            padding-right: 24px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .isDesktop & {
                padding-left: 15%;
            }
            .isDesktop .#{$component}--LeftForm{
                margin-bottom: 16px;
            }
            .isDesktop &.isContactForm{
                // width: 100%;
                .#{$component}--LeftForm{
                    width: 49%;
                    float: left;
                }
                & .#{$component}--Fields > .rs-form-group{
                    width: 49%;
                    float: right;
                    height: 100%;
                    textarea{
                        height: 100%;
                    }
                }
            }
        }
    }
}


.#{$component}--Fields{
    margin-bottom:16px;
}