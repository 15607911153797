.Publicaciones--Premios{
    margin: 24px;
}

.StatsModule{
    display: flex;
    flex-direction: row;
    .Icon{
        float: left;
        img{
            width: 64px;
            height: 64px;
            .isMobile &{
                height: 40px;
            }
            margin-right: 24px;
            margin-top: 24px;
            margin-bottom: 24px;
            .isMobile &{
                margin-top: 12px;
                margin-bottom: 12px;
            }
        }
    }
    .Texts{
        float: left;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    span {
        font-weight: bold;
        font-size: 24px;
    }
    h4{
        font-size: 18px;
        font-weight: lighter;
        line-height: 18px;
        small{

        }
    }
    margin-bottom: 8px;
}